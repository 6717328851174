import React, { Component } from 'react';
import './App.css';
import './dropdown.css'

import en from './translations/en.json';
import si from './translations/si.json';
import hr from './translations/hr.json';

import peterPurg from './profile_pics/peter_purg_cut.jpg';
import sasoKramberger from './profile_pics/saso_kramberger_cut.jpg';
import jakobPurg from './profile_pics/jakob_purg_cut.jpg';
import lukaHvalec from './profile_pics/luka_hvalec_cut.jpg';
import tomazKramberger from './profile_pics/tomaz_kramberger_cut.jpg';

import PropTypes from 'prop-types';

import dockin_logo_cut from './dockin_logo_new.png';
import menu_icon from './menu.svg';

import {
    setTranslations,
    setDefaultLanguage,
    setLanguage,
    translate
} from 'react-switch-lang';

setTranslations({ en, si, hr });
setDefaultLanguage('en');

class App extends Component {
    defaultLanguage;
    constructor(props) {
        super(props);
        this.defaultLanguage="ENGLISH";
        this.aboutDiv = React.createRef();
        this.userDiv = React.createRef();
        this.concessionaireDiv = React.createRef();
        this.teamDiv = React.createRef();
    }

    handleSetLanguage(lang) {
        setLanguage(lang);
        var iframeUser = document.getElementById('videoIFrameUser');
        var iframeCon = document.getElementById('videoIFrameCon');
        if(lang==='en') {
            this.defaultLanguage = "ENGLISH";
            iframeUser.src = 'https://www.youtube.com/embed/nF4j5WQVsMw?controls=0&showinfo=0&rel=0&autoplay=0&loop=1';
            iframeCon.src = 'https://www.youtube.com/embed/UaEsnkO32NA?controls=0&showinfo=0&rel=0&autoplay=0&loop=1';
        }
        else if(lang==='si') {
            this.defaultLanguage = "SLOVENSKI";
            iframeUser.src = 'https://www.youtube.com/embed/M5gXRvujKDk?controls=0&showinfo=0&rel=0&autoplay=0&loop=1';
            iframeCon.src = 'https://www.youtube.com/embed/wM2-nxC0umg?controls=0&showinfo=0&rel=0&autoplay=0&loop=1';
        }
        else if(lang==='hr') {
            this.defaultLanguage = "HRVATSKI";
            iframeUser.src = 'https://www.youtube.com/embed/vupn3-QaAv4?controls=0&showinfo=0&rel=0&autoplay=0&loop=1';
            iframeCon.src = 'https://www.youtube.com/embed/EuYVVYN8pkc?controls=0&showinfo=0&rel=0&autoplay=0&loop=1';
        }
    };
    handleAboutClick = (event) => {
        if(this.aboutDiv.current) {
            this.aboutDiv.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };
    handleUserClick = (event) => {
        if(this.userDiv.current) {
            this.userDiv.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
    };
    handleConcClick = (event) => {
        if(this.concessionaireDiv.current) {
            this.concessionaireDiv.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
    };
    handleTeamClick = (event) => {
        if(this.teamDiv.current) {
            this.teamDiv.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
    };
    render() {
      const { t } = this.props;
    return (
        <div className="layout">
            <head>
                <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>
            </head>
            <header className="pub-header" role="banner">
                <div className="pub-inner container">
                    <div className="header-col-left upper-mobile grow-flexible">
                        <img alt="Dockin logo" className="logo-size" src={dockin_logo_cut}/>
                        <img alt="Menu" className="menu-img" src={menu_icon} />
                        <div className="header-col-left-inner mobile-links grow-flexible-inner" id="links">
                            <a className="nav-item nowrap underline" onClick={this.handleAboutClick}>
                                {t('about.about')}
                            </a>
                            <a className="nav-item nowrap underline" onClick={this.handleUserClick}>
                                {t('about.end_user')}
                            </a>
                            <a className="nav-item nowrap underline" onClick={this.handleConcClick}>
                                {t('about.commissioner')}
                            </a>
                            <a className="nav-item nowrap underline" onClick={this.handleTeamClick}>
                                {t('about.team')}
                            </a>
                        </div>
                    </div>
                    <div className="header-col-middle">
                        <img alt="Dockin logo" className="logo-size-mid" src={dockin_logo_cut}/>
                    </div>
                    <div className="header-col-right">
                        <div className="button-div" onFocus="this.blur()">
                            <button className="dropbtn">{this.defaultLanguage}</button>
                            <div className="dropdown-content">
                                <p onClick={()=>this.handleSetLanguage('en')}>ENGLISH</p>
                                <p onClick={()=>this.handleSetLanguage('si')}>SLOVENSKI</p>
                                <p onClick={()=>this.handleSetLanguage('hr')}>HRVATSKI</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main id="content" role="main" className="main">
                <div className="side-menu-backdrop">
                </div>
                <section className="side-menu no-active-tab">
                    <div className="scrollable-container p-t-xs">
                        <div>
                        </div>
                    </div>
                </section>
                <div className="content-body no-active-tab">
                    <div className="mr ml alert-box">
                    </div>
                    <div className="pg-body bg-color-gray about">
                        <div className="container">
                            <div className="row">
                                <div className="mx-auto col-lg-10">
                                    <section ref={this.aboutDiv} className="card card-lg" id="about-about">
                                        <h2 className="text-center m-b font-w">
                                            {t('about.about')}
                                        </h2>
                                        <div className="text-justify mx-auto m-x-w">
                                            <p>{t('about.description_company_1')}</p>
                                            <p><b>{t('about.description_company_when')}</b></p>
                                            <p>{t('about.description_company_2')}</p>
                                        </div>
                                    </section>
                                    <section ref={this.userDiv} className="card" id="about-user">
                                        <div className="youtube-video-con">
                                            <iframe width="560" height="349" id="videoIFrameUser" src="https://www.youtube.com/embed/nF4j5WQVsMw?controls=0&showinfo=0&rel=0&autoplay=0&loop=1" frameBorder="0"
                                                    allowFullScreen/>
                                        </div>
                                    </section>
                                    <section ref={this.concessionaireDiv} className="card" id="about-commissionaire">
                                        <div  className="youtube-video-con">
                                            <iframe width="560" height="349" id="videoIFrameCon" src="https://www.youtube.com/embed/UaEsnkO32NA?controls=0&showinfo=0&rel=0&autoplay=0&loop=1" frameBorder="0"
                                                    allowFullScreen/>
                                        </div>
                                    </section>
                                    <section ref={this.teamDiv} className="card card-lg p-b-0 text-center" id="about-team">
                                        <h2 className="text-center m-b font-w">{t('about.team')}</h2>
                                        <div className="row">
                                            <div className="col-sm-6 col-xxl-3 mb-xxl">
                                                <img src={peterPurg} className="profile-pic img-fluid m-x-auto m-b" alt="Img"/>
                                                <h3 className="t-md t-b">Peter Purg</h3>
                                                <p className="text-xxxs mb-sm t-up">{t('about.description_peter_purg_title')}</p>
                                                <p className="text-xs mb-sm mx-auto text-justify">{t('about.description_peter_purg_desc')}</p>
                                            </div>
                                            <div className="col-sm-6 col-xxl-3 mb-xxl">
                                                <img src={lukaHvalec} className="profile-pic img-fluid m-x-auto m-b" alt="Img"/>
                                                <h3 className="t-md t-b">Luka Hvalec</h3>
                                                <p className="text-xxxs mb-sm t-up">{t('about.description_luka_hvalec_title')}</p>
                                                <p className="text-xs mb-sm mx-auto text-justify">{t('about.description_luka_hvalec_desc')}</p>
                                            </div>
                                            <div className="col-sm-6 col-xxl-3 mb-xxl">
                                                <img src={jakobPurg} className="profile-pic img-fluid m-x-auto m-b" alt="Img"/>
                                                <h3 className="t-md t-b">Jakob Purg</h3>
                                                <p className="text-xxxs mb-sm t-up">{t('about.description_jakob_purg_title')}</p>
                                                <p className="text-xs mb-sm mx-auto text-justify">{t('about.description_jakob_purg_desc')}</p>
                                            </div>
                                            <div className="col-sm-6 col-xxl-3 mb-xxl">
                                                <img src={sasoKramberger} className="profile-pic img-fluid m-x-auto m-b" alt="Img"/>
                                                <h3 className="t-md t-b">Sašo Kramberger</h3>
                                                <p className="text-xxxs mb-sm t-up">{t('about.description_saso_kramberger_title')}</p>
                                                <p className="text-xs mb-sm mx-auto text-justify">{t('about.description_saso_kramberger_desc')}</p>
                                            </div>
                                            <div className="col-sm-6 col-xxl-3 mb-xxl">
                                                <img src={tomazKramberger} className="profile-pic img-fluid m-x-auto m-b" alt="Img"/>
                                                <h3 className="t-md t-b">Tomaž Kramberger</h3>
                                                <p className="text-xxxs mb-sm t-up">{t('about.description_tomaz_kramberger_title')}</p>
                                                <p className="text-xs mb-sm mx-auto text-justify">{t('about.description_tomaz_kramberger_desc')}</p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
  }
}

App.propTypes = {
  t:PropTypes.func.isRequired,
};

export default translate(App);
